import { useEffect, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useIntl } from 'react-intl';

import { getMetasForThePage_req } from '../../../api/meta.api';

// import roboto from '../../../assets/fonts/roboto';
import icomoon from '../../../assets/fonts/icomoon';

import websiteConfig from '../../../configs/websiteConfig';
import usePrevious from '../../../hooks/previous.hook';

export default function MetaWrapper({ metas }) {
  const intl = useIntl();

  const { pathname, asPath, locale, query } = useRouter();
  const prevAsPath = usePrevious(asPath);

  const [dynamicMetas, setDynamicMetas] = useState(metas || []);

  const getMetasForThePage = useCallback(async () => {
    try {
      if (prevAsPath === asPath) {
        return;
      }

      let url = asPath;
      let val = '';

      if (query?.provider) {
        url = `/${query.category}`;
        val = query.provider;
      }

      const pageMetas = await getMetasForThePage_req(null, url, val, locale);
      if (pageMetas?.length && pageMetas.length) {
        setDynamicMetas(pageMetas);
      }
    } catch (e) {
      console.error('MetaWrapper -> getMetasForThePage Error:', e);
    }
  }, [pathname, asPath, locale, query, prevAsPath]);

  useEffect(() => {
    getMetasForThePage();
  }, [pathname, asPath, locale, query]);

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5"
      />
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      {process.env.NEXT_PUBLIC_ENV !== 'production' && (
        <meta name="robots" content="noindex" />
      )}
      {dynamicMetas?.length ? (
        dynamicMetas.map((meta) => {
          if (meta.Tag === 'title') {
            return <title key="title">{meta.Description}</title>;
          }

          return (
            <meta name={meta.Tag} content={meta.Description} key={meta.Tag} />
          );
        })
      ) : (
        <>
          <title key="title">
            {intl.formatMessage(
              {
                id: 'meta_title_main',
              },
              {
                country: websiteConfig.country,
                websiteName: websiteConfig.name,
              }
            )}
          </title>
          <meta
            name="description"
            content={intl.formatMessage(
              {
                id: 'meta_description_main',
              },
              {
                country: websiteConfig.country,
                websiteName: websiteConfig.name,
              }
            )}
            key="description"
          />
          <meta
            name="keywords"
            content={`online casino, casino ${websiteConfig.country}, casino bet, spin win, casino games, bet online, casino site`}
            key="keywords"
          />
          <meta
            property="og:description"
            content={intl.formatMessage(
              {
                id: 'meta_description_main',
              },
              {
                country: websiteConfig.country,
                websiteName: websiteConfig.name,
              }
            )}
            key="og:description"
          />
          <meta
            property="og:image"
            content={`${websiteConfig.url}/social/social-og.png`}
            key="og:image"
          />
          <meta
            property="og:title"
            content={intl.formatMessage(
              {
                id: 'meta_title_main',
              },
              {
                country: websiteConfig.country,
                websiteName: websiteConfig.name,
              }
            )}
            key="og:title"
          />
          <meta
            property="og:url"
            content={`${websiteConfig.url}${asPath}`}
            key="og:url"
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:site" content={websiteConfig.url} />
          <meta
            name="twitter:title"
            key="twitter:title"
            content={intl.formatMessage(
              {
                id: 'meta_title_main',
              },
              {
                country: websiteConfig.country,
                websiteName: websiteConfig.name,
              }
            )}
          />
          <meta
            name="twitter:description"
            key="twitter:description"
            content={intl.formatMessage(
              {
                id: 'meta_description_main',
              },
              {
                country: websiteConfig.country,
                websiteName: websiteConfig.name,
              }
            )}
          />
          <meta
            name="twitter:image"
            key="twitter:image"
            content={`${websiteConfig.url}/social/social-og.png`}
          />
        </>
      )}
      {/*<style dangerouslySetInnerHTML={roboto} />*/}
      <style dangerouslySetInnerHTML={icomoon} />
    </Head>
  );
}
